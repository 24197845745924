<template>
  <div class="words-setting">
    <h2>功能开放设置</h2>
    <ul>
      <li>
        <span>开放功能</span>
        <span>开放按钮</span>
      </li>
      <li v-for="item in list.data" :key="item.id">
        <span>{{item.name}}</span>
        <el-switch v-model="item.state" @change="edit(item)"></el-switch>
      </li>
    </ul>
    <h2>版本审核设置</h2>
    <ul>
      <li>
        <span>ios版本审核开关</span>
        <el-input style="margin-right:20px;" v-model="version" placeholder="不同版号之间用,隔开"></el-input>
        <el-button type="primary" @click="updateVersion">保存</el-button>
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent, reactive, ref } from '@vue/composition-api'

export default defineComponent({
  setup(props,{root}) {
    const params = reactive({
      avartar: ''
    })
    const version = ref('')
    const list = reactive({data: {}})
    const getList = () => {
      root.$axios.get('/rcp/systemManage/config/list')
      .then(res=>{
        Object.keys(res.data).forEach(key=>{
          res.data[key].state = res.data[key].state === 2
        })
        list.data = res.data
      })
      
    }
    getList()
    const edit = data => {
      root.$axios.post('/rcp/systemManage/config/updateState', {
        id: data.id,
        state: data.state ? 2:1
      })
      .then(res=>{
        root.$message.success(res.msg)
        getList()
      })
      
    }
    const getVersion = () =>{
      root.$axios.get('/rcp/extraConfig/versionAudit/getVersion')
      .then(res=>{
        version.value = res.data.content
      })
      
    }
    const updateVersion = () => {
      root.$axios.post('/rcp/extraConfig/versionAudit/updateVersion',{
        id: 1,
        content: version.value
      })
      .then(res=>{
        root.$message.success(res.msg)
      })
      
    }
    getVersion()
    return {
      params,
      list,
      version,
      edit,
      updateVersion
    }
  },
})
</script>
<style lang="scss" scoped>
.words-setting{
  h2{
    margin-bottom: 20px;
  }
  ul{
    width: 500px;
    li{
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span{
        white-space: nowrap;
        margin-right: 30px;
      }
    }
  }
}
</style>